import Vue from 'vue'

const components = {
  'info-title': 'Title',
  'info-paragraph': 'Paragraph',
  'text': 'Text',
  'select': 'Select',
  'date': 'Date',
  'email': 'Email',
  'phone': 'Phone',
  'switch': 'Switch',
  'checkbox': 'Checkbox',
  'file': 'File',
  'image': 'Image',
  'radio': 'Radio',
}

export default {
  functional: true,
  props: {
    type: { type: String, required: true },
    data: { type: Object, default: null },
    isPublished: { type: Boolean, default: false },
    isLastField: { type: Boolean, default: false },
    canEdit: { type: Boolean, default: true },
  },
  render(createElement, { props: { type, data, isPublished, isLastField, canEdit } }) {
    const element = `DynamicFormField${components[type]}`
    Vue.component(element, require(`@/modules/dynamicForm/formFieldsComponents/formFieldsComponentsSelectors/${element}`).default)
    return createElement(element, { props: { data, isPublished, isLastField, canEdit } })
  },
}
