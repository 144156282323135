<template>
  <div>
    <v-text-field
      v-model="form.label"
      outlined
      dense
      :label="$t('project.form.components.fieldSelect')"
      :error="!$store.getters['dynamicForm/allFieldsFilledOut'] && $v.form.label.$invalid"
      :error-messages="getFieldErrors('label')"
      @blur="$v.form.label.$touch()"
      @input="
        updateFormField();
        $store.commit(
          'dynamicForm/changeFilledState',
          !$v.form.optionsType.$invalid
            && !$v.form.label.$invalid
            && $store.getters['dynamicForm/allFieldsFilledOut']
        )
      "
    />
    <v-expansion-panels v-model="panel" flat>
      <v-expansion-panel>
        <v-expansion-panel-header class="pa-0">
          <template #actions>
            <v-icon color="primary" class="icon">
              $expand
            </v-icon>
          </template>
          <span class="font-weight-bold header" v-text="$t('project.form.customize')" />
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-0">
          <v-row class="pl-3">
            <v-radio-group
              v-model="form.optionsType"
              hide-details="auto"
              :error="!$store.getters['dynamicForm/allFieldsFilledOut'] && $v.form.optionsType.$invalid"
            >
              <v-radio
                v-for="(option, i) in optionsType"
                :key="i"
                :label="$t(`project.form.selectors.${option}`)"
                :disabled="isPublished && !isLastField"
                :value="option"
                @change="updateOptions(option)"
              />
              <v-col v-if="isCustomSelected" class="pl-10" style="width: 100%;">
                <v-row>
                  <span class="my-2" v-text="$t('project.form.selectors.addOptionItems')" />
                  <v-text-field
                    v-for="(option, i) in form.options"
                    :key="i"
                    v-model="form.options[i].label"
                    outlined
                    dense
                    :error="!$store.getters['dynamicForm/allFieldsFilledOut'] && $v.form.options.$each[i].$dirty && $v.form.options.$each[i].$invalid"
                    :label="`${$t('common.item')} ${i + 1}`"
                    style="width: 100%;"
                    @change="updateFormStatus()"
                    @blur="$v.form.options.$each[i].label.$touch()"
                  >
                    <template #append-outer>
                      <v-btn
                        icon
                        small
                        color="primary"
                        @click="form.options = form.options.filter((_, v) => v !== i); updateFormStatus()"
                      >
                        <v-icon
                          dark
                        >
                          delete_outline
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-row>
                <v-row
                  class="d-flex flex-row align-center mt-3"
                  @click="
                    form.options.push({ id: `${getUniqueId()}` }); updateFormStatus()"
                >
                  <v-btn
                    icon
                    x-small
                    color="primary"
                  >
                    <v-icon
                      dark
                    >
                      add_box
                    </v-icon>
                  </v-btn>
                  <span
                    class="ml-2 primary--text"
                  >
                    {{ $t(`project.form.selectors.addItem`) }}
                  </span>
                </v-row>
              </v-col>
            </v-radio-group>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox
                v-model="form.isRequired"
                :label="$t('project.form.selectors.isRequired')"
                hide-details="auto"
                @change="updateFormField()"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'
import { getUniqueId } from '@/utils/formatters'

export default {
  name: 'ProjectDynamicFormSelect',
  mixins: [formUtils],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    isPublished: {
      type: Boolean,
      default: false,
    },
    isLastField: { type: Boolean, default: false },
  },
  data() {
    return {
      form: {},
      optionsType: [
        'countries',
        // 'languages', // TODO: CRIS: NO MVP https://owqlo.atlassian.net/browse/OW-2912
        'custom',
      ],
      panel: 0,
      getUniqueId,
    }
  },
  validations() {
    return {
      form: {
        label: { required },
        optionsType: { required },
        options: {
          $each: {
            label: { required: requiredIf(() => this.isCustomSelected) },
          },
        },
      },
    }
  },
  computed: {
    isCustomSelected: ({ form }) => form.optionsType === 'custom',
  },
  created() {
    this.form = {
      id: this.data?.id,
      label: this.data?.label ?? null,
      type: this.data?.type ?? 'select',
      isRequired: this.data?.isRequired ?? false,
      ...(this.data?.titleText && ({ titleText: this.data.titleText })),
      optionsType: this.data?.optionsType ?? null,
      ...(this.data?.options ? ({ options: this.data?.options }) : ({ options: [...Array(3)].map((_, i) => ({ id: `${getUniqueId()}`, label: null })) })),
      page: this.data.page,
    }
  },
  methods: {
    updateOptions(optionsType) {
      const isCustom = optionsType === 'custom'
      const { options, ...rest } = this.form
      const data = isCustom ? this.form : { ...rest }
      this.updateFormField({ ...data, optionsType })
      this.$store.commit(
        'dynamicForm/changeFilledState',
        isCustom
          ? this.form.options.every(option => !!option.label)
          : true,
      )
    },
    updateFormStatus() {
      this.updateFormField()
      this.$store.commit(
        'dynamicForm/changeFilledState',
        this.form.options.every(option => !!option.label),
      )
    },
  },
}
</script>

<style>
.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 16px !important;
}

.icon {
  order: 0;
}

.header {
  order: 1;
}
</style>
