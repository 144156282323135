<template>
  <div>
    <!-- https://owqlo.atlassian.net/browse/OW-2912  -->
    <!-- <v-text-field
      v-model="form.label"
      outlined
      dense
      :label="$t('project.form.components.fieldRadio')"
      :error="!$store.getters['dynamicForm/allFieldsFilledOut'] && !form.label"
      :error-messages="getFieldErrors('label')"
      @blur="$v.form.label.$touch()"
      @input="updateFormField()"
    /> -->
    <v-expansion-panels v-model="panel" flat>
      <v-expansion-panel>
        <v-expansion-panel-header class="pa-0">
          <template #actions>
            <v-icon color="primary" class="icon">
              $expand
            </v-icon>
          </template>
          <span class="font-weight-bold header" v-text="$t('project.form.customize')" />
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-0">
          <v-row>
            <v-col class="pl-6">
              <v-row>
                <span class="my-2" v-text="$t('project.form.selectors.addRadioItems')" />
              </v-row>
              <v-row>
                <v-text-field
                  v-for="(option, i) in form.options"
                  :key="i"
                  v-model="form.options[i].label"
                  outlined
                  dense
                  :error="!$store.getters['dynamicForm/allFieldsFilledOut'] && !form.options[i].label"
                  style="width: 100%;"
                  :label="`${$t('common.item')} ${i + 1}`"
                  @change="updateFormField(); $store.commit('dynamicForm/changeFilledState', !!(form.options[i].label))"
                >
                  <template #append-outer>
                    <v-btn
                      icon
                      small
                      color="primary"
                      @click="form.options = form.options.filter((_, v) => v !== i); updateFormField()"
                    >
                      <v-icon
                        dark
                      >
                        delete_outline
                      </v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-row>
              <v-row
                class="d-flex flex-row align-center mt-3"
                @click="form.options.push({ id: `${form.options.length}` })"
              >
                <v-btn
                  icon
                  x-small
                  color="primary"
                >
                  <v-icon
                    dark
                  >
                    add_box
                  </v-icon>
                </v-btn>
                <span
                  class="ml-2 primary--text"
                >
                  {{ $t(`project.form.selectors.addItem`) }}
                </span>
              </v-row>
            </v-col>
          </v-row>
          <!--TODO: NO MVP  https://owqlo.atlassian.net/browse/OW-2912 -->
          <!-- <v-row>
            <v-col>
              <v-checkbox v-model="form.isRequired" :label="$t('project.form.selectors.isRequired')" hide-details="auto" @change="updateFormField()" />
            </v-col>
          </v-row> -->
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'ProjectDynamicFormRadio',
  mixins: [formUtils],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: { label: 'default' }, // CRIS: https://owqlo.atlassian.net/browse/OW-2912
      panel: 0,
    }
  },
  validations() {
    return {
      form: {
      },
    }
  },
  created() {
    this.form = {
      id: this.data?.id,
      label: this.data?.label ?? 'Radio button',
      ...(this.data?.titleText && ({ titleText: this.data.titleText })),
      ...(this.data?.options ? ({ options: this.data?.options }) : ({ options: [...Array(3)].map((_, i) => ({ id: `${i}`, label: null })) })),
      type: this.data?.type ?? 'radio',
      isRequired: this.data?.isRequired ?? false,
      page: this.data.page,
      optionsType: 'custom',
    }
  },
}
</script>

<style>
.icon {
  order: 0;
}

.header {
  order: 1;
}
</style>
