<template>
  <div>
    <v-text-field
      v-model="form.label"
      outlined
      dense
      :label="$t('project.form.components.fieldDate')"
      :error="!$store.getters['dynamicForm/allFieldsFilledOut'] && $v.form.label.$invalid"
      :error-messages="getFieldErrors('label')"
      :disabled="!canEdit"
      @blur="$v.form.label.$touch()"
      @input="updateFormField(); $store.commit('dynamicForm/changeFilledState', !$v.form.label.$invalid)"
    />
    <v-expansion-panels v-model="panel" flat>
      <v-expansion-panel>
        <v-expansion-panel-header class="pa-0">
          <template #actions>
            <v-icon color="primary" class="icon">
              $expand
            </v-icon>
          </template>
          <span class="font-weight-bold header" v-text="$t('project.form.customize')" />
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-0">
          <!--TODO: NO MVP  https://owqlo.atlassian.net/browse/OW-2912 -->
          <!-- <v-row class="pl-3">
            <v-radio-group v-model="selectedOption" hide-details="auto">
              <v-radio
                v-for="(option, i) in optionsType"
                :value="option"
                :key="i"
                :label="$t(`project.form.selectors.${option}`)"
                @change="optionsType.forEach(type => form[type] = type === optionsType[i]); selectedOption = option; updateFormField()"
              />
            </v-radio-group>
          </v-row> -->
          <v-row>
            <v-col>
              <v-checkbox v-model="form.isRequired" :label="$t('project.form.selectors.isRequired')" hide-details="auto" :disabled="!canEdit" @change="updateFormField()" />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'ProjectDynamicFormSelect',
  mixins: [formUtils],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    canEdit: { type: Boolean, default: true },
  },
  data() {
    return {
      form: {},
      selectedOption: null,
      optionsType: [
        'onlyPastDates',
        'onlyFutureDates',
        'pastOrFutureDates',
      ],
      panel: 0,
    }
  },
  validations() {
    return {
      form: {
        label: { required },
      },
    }
  },
  created() {
    this.form = {
      id: this.data?.id,
      label: this.data?.label ?? null,
      type: this.data?.type ?? 'date',
      isRequired: this.data?.isRequired ?? false,
      onlyPastDates: this.data?.onlyPastDates ?? false,
      onlyFutureDates: this.data?.onlyFutureDates ?? false,
      pastOrFutureDates: this.data?.pastOrFutureDates ?? false,
      page: this.data.page,
    }
    this.selectedOption = this.optionsType.find(option => this.form[option])
  },
}
</script>

<style>
.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 16px !important;
}

.icon {
  order: 0;
}

.header {
  order: 1;
}
</style>
