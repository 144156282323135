<template>
  <div>
    <v-text-field
      v-model="form.text"
      outlined
      dense
      :label="$t('project.form.components.fieldParagraph')"
      :error="!$store.getters['dynamicForm/allFieldsFilledOut'] && $v.form.text.$invalid"
      :error-messages="getFieldErrors('text')"
      :disabled="!canEdit"
      @blur="$v.form.text.$touch()"
      @input="updateFormField(); $store.commit('dynamicForm/changeFilledState', !$v.form.text.$invalid)"
    />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'ProjectDynamicFormParagraph',
  mixins: [formUtils],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    canEdit: { type: Boolean, default: true },
  },
  data() {
    return {
      form: {},
      panel: 0,
    }
  },
  validations() {
    return {
      form: {
        text: { required },
      },
    }
  },
  created() {
    this.form = {
      id: this.data?.id,
      text: this.data?.text ?? null,
      type: this.data?.type ?? 'info-paragraph',
      page: this.data.page,
      isInfoOnly: true,
    }
  },
}
</script>
