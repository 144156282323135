<template>
  <div>
    <v-text-field
      v-model="form.label"
      outlined
      dense
      :label="$t('project.form.components.fieldSwitch')"
      :error="!$store.getters['dynamicForm/allFieldsFilledOut'] && $v.form.label.$invalid"
      :error-messages="getFieldErrors('label')"
      @blur="$v.form.label.$touch()"
      @input="updateFormField(); $store.commit('dynamicForm/changeFilledState', !$v.form.label.$invalid)"
    />
    <!--TODO: NO MVP  https://owqlo.atlassian.net/browse/OW-2912 -->
    <!-- <v-expansion-panels flat v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header class="pa-0">
          <template v-slot:actions>
              <v-icon color="primary" class="icon">$expand</v-icon>
          </template>
          <span class="font-weight-bold header" v-text="$t('project.form.customize')" />
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-0">
          <v-row>
            <v-col class="py-0">
              <v-checkbox v-model="hasTitleText" :label="$t('project.form.selectors.hasTitleTextLabel')" hide-details="auto" @change="updateFormField({ ...form, titleText: null })" class="my-4" />
            </v-col>
          </v-row>
          <v-row v-if="hasTitleText" class="pl-10 py-3">
            <span v-text="$t('project.form.selectors.hasTitleTextDescription')" />
            <v-text-field
              v-model="form.titleText"
              outlined
              dense
              :error="!$store.getters['dynamicForm/allFieldsFilledOut'] && !form.titleText"
              hide-details="auto"
              class="my-4"
              :label="$t('project.form.selectors.titleText')"
              :error-messages="getFieldErrors('titleText')"
              @blur="$v.form.titleText.$touch()"
              @input="updateFormField()"
            />
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels> -->
  </div>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'ProjectDynamicFormSwitch',
  mixins: [formUtils],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {},
      hasTitleText: false,
      panel: 0,
    }
  },
  validations() {
    return {
      form: {
        label: { required },
        titleText: { required: requiredIf(() => this.hasTitleText) },
      },
    }
  },
  created() {
    this.form = {
      id: this.data?.id,
      label: this.data?.label ?? null,
      ...(this.data?.titleText && ({ titleText: this.data.titleText })),
      type: this.data?.type ?? 'switch',
      isRequired: false,
      page: this.data.page,
    }
    this.hasTitleText = !!(this.form?.titleText)
  },
}
</script>

<style>
.icon {
  order: 0;
}

.header {
  order: 1;
}
</style>
