<template>
  <div>
    <v-text-field
      v-model="form.label"
      outlined
      dense
      :label="$t('project.form.components.fieldFile')"
      :error="!$store.getters['dynamicForm/allFieldsFilledOut'] && $v.form.label.$invalid"
      :error-messages="getFieldErrors('label')"
      @blur="$v.form.label.$touch()"
      @input="updateFormField(); $store.commit('dynamicForm/changeFilledState', !$v.form.label.$invalid)"
    />
    <v-expansion-panels v-model="panel" flat>
      <v-expansion-panel>
        <v-expansion-panel-header class="pa-0">
          <template #actions>
            <v-icon color="primary" class="icon">
              $expand
            </v-icon>
          </template>
          <span class="font-weight-bold header" v-text="$t('project.form.customize')" />
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-0">
          <v-row>
            <v-col>
              <v-checkbox v-model="form.isRequired" :label="$t('project.form.selectors.isRequired')" hide-details="auto" @change="updateFormField()" />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'ProjectDynamicFormFile',
  mixins: [formUtils],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {},
      panel: 0,
    }
  },
  validations() {
    return {
      form: {
        label: { required },
      },
    }
  },
  created() {
    this.form = {
      id: this.data?.id,
      label: this.data?.label ?? null,
      type: this.data?.type ?? 'file',
      isRequired: this.data?.isRequired ?? false,
      page: this.data.page,
    }
  },
}
</script>

<style>
.icon {
  order: 0;
}

.header {
  order: 1;
}
</style>
